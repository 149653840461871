const invoiceOptions = [
  { value: 'open', label: 'Paid vs Outstanding' },
  {
    value: 'old_companies',
    label: 'All companies vs Companies with at least one invoice in a month',
  },
  {
    value: 'new_companies',
    label:
      'New Companies vs New Companies with at least one invoice in a month',
  },
  {
    value: 'top_debtors',
    label: 'Top 10 Debtors',
  },
];

const invoiceTypeOptions = [
  { value: 'all', label: 'All types of invoices' },
  { value: 'gcp', label: 'GCP invoices' },
  { value: 'co', label: 'CloudOps invoices' },
];

const debtorsTypeOptions = [
  { value: 'highest_balance', label: 'Highest outstanding balance' },
  { value: 'oldest_date', label: 'Oldest past due invoices' },
  { value: 'highest_count', label: 'Highest count of past due invoices' },
];

// const invoiceCurrencyOptions = [
//   { value: 'USD', label: 'USD' },
//   { value: 'EUR', label: 'Euro' },
//   { value: 'GBP', label: 'GBP' },
// ];

const invoiceCurrencyOptions = [
  {
    label: 'US QuickBooks',
    values: [{ label: 'USD', value: 'usa-USD' }],
  },
  // {
  //   label: 'Canadian QuickBooks',
  //   values: [{ label: 'CAD', value: 'cad-CAD' }],
  // },
  {
    label: 'European QuickBooks',
    values: [
      { label: 'EUR', value: 'europe-EUR' },
      { label: 'GBP', value: 'europe-GBP' },
      { label: 'USD', value: 'europe-USD' },
    ],
  },
];

export {
  invoiceOptions,
  invoiceTypeOptions,
  invoiceCurrencyOptions,
  debtorsTypeOptions,
};
