import React from 'react';
import styled from 'styled-components';
import {
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { neutrals, reds } from 'utils/styles/color';
import { palette } from 'utils/styles/variables';

const ModalWrapper = styled(Dialog)<{
  $width: Breakpoint;
}>`
  && {
    .MuiPaper-root {
      gap: 24px;
      width: ${({ $width }) => {
        switch ($width) {
          case 'xs':
            return '480px';
          case 'sm':
            return '480px';
          case 'md':
            return '600px';
          default:
            return '900px';
        }
      }};
      min-width: ${({ $width }) => {
        switch ($width) {
          case 'xs':
            return '480px';
          case 'sm':
            return '480px';
          case 'md':
            return '600px';
          default:
            return '900px';
        }
      }};
    }
  }
`;

const ModalTitle = styled(DialogTitle)<{
  $padding24?: boolean;
}>`
  && {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    padding: ${({ $padding24 }) =>
      $padding24 ? '24px 16px 0' : '16px 16px 0'};
  }
`;

const ModalTitleText = styled(({ ...props }) => (
  <Typography component="h2" {...props} />
))`
  && {
    color: ${neutrals[800]};

    font-family: Poppins, sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }
`;

const ModalSubtitleText = styled(({ ...props }) => (
  <Typography component="h6" {...props} />
))`
  && {
    flex: 1 0 0;

    color: ${neutrals[800]};

    font-family: Poppins, sans-serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }
`;

const ModalTitleCloseButton = styled(IconButton)`
  && {
    align-self: flex-start;
    padding: 0;
    color: ${reds[700]};
  }
`;

/**
 * BELOW THE COMPONENTS THAT WILL BE IMPORTED BY
 * COMPONENTS THAT USE IT
 */
const ModalContent = styled(DialogContent)<{
  $gap?: number;
}>`
  && {
    padding: 0 16px;
    ${({ $gap }) =>
      $gap && {
        display: 'flex',
        flexDirection: 'column',
        gap: `${$gap}px`,
      }}
  }
`;

const ModalActions = styled(DialogActions)<{
  $padding24?: boolean;
}>`
  && {
    padding: ${({ $padding24 }) =>
      $padding24 ? '0 16px 24px' : '0 16px 16px'};
    gap: 16px;
    .MuiButton-root {
      min-width: 104px;
      margin: 0;
    }
  }
`;

const ModalActionsFullWidth = styled(DialogActions)`
  && {
    padding: 0 16px 16px;

    flex-direction: column;
    gap: 16px;
  }
`;

const ModalCloseButton = styled(Button)`
  && {
    display: flex;
    height: 32px;
    padding: 4px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: justify;

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;

    background-color: #fff;

    color: ${palette.primary.main};
    border-color: ${palette.primary.main};

    &:hover {
      color: ${palette.primary.main};
      background-color: #6f9fe926;
      border: 1px solid ${palette.primary.main};
    }
  }
`;

const ModalSubmitButton = styled(Button)`
  && {
    display: flex;
    height: 32px;
    padding: 4px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: justify;

    font-family: Poppins, sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    text-transform: uppercase;

    border-color: ${palette.primary.main};
    background-color: ${palette.primary.main};

    &:hover {
      background-color: #8aafe9;
      border-color: #8aafe9;
    }

    &:disabled {
      background: #e8e9eb;
      border-color: #e8e9eb;
    }
  }
`;

export {
  ModalWrapper,
  ModalTitle,
  ModalTitleText,
  ModalSubtitleText,
  ModalTitleCloseButton,
  ModalContent,
  ModalActions,
  ModalActionsFullWidth,
  ModalCloseButton,
  ModalSubmitButton,
};
