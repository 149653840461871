import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportData } from 'pages/CloudOpsReportAdmin/types';
import axios from 'axios';

const initialState: ReportData = {
  count: 0,
  projects_reports: [],
};

export const getCloudOpsReports = createAsyncThunk(
  'cloudOps/getReports',
  async ({
    company_hs_id,
    limit,
    page,
  }: {
    company_hs_id: string;
    limit: number;
    page: number;
  }) => {
    const millisecondsToHours = (milliseconds: number) =>
      milliseconds / 1000 / 60 / 60;

    try {
      const { data } = await axios.get<ReportData>(
        `${process.env.REACT_APP_USER_SERVICE}/projects/reports`,
        {
          params: {
            company_hs_id,
            limit,
            page: page + 1,
          },
        }
      );
      data.projects_reports = data.projects_reports.map((item) => {
        const monthly_budget_in_hours = item.project.monthly_budget_in_hours
          ? Math.round(item.project.monthly_budget_in_hours * 10) / 10
          : 0;

        let credit_balance = monthly_budget_in_hours
          ? monthly_budget_in_hours - millisecondsToHours(item.time_tracked)
          : 0;
        credit_balance = Math.round(credit_balance * 10) / 10;
        const minimalMonthlyBudget = item.project.minimum_monthly_budget
          ? item.project.minimum_monthly_budget
          : 0;
        const overBudgetHourlyRate = item.project.over_budget_hourly_rate
          ? item.project.over_budget_hourly_rate
          : 0;

        let curent_consumption = item.time_tracked ? item.time_tracked : 0;
        curent_consumption = millisecondsToHours(curent_consumption);
        const overConsumedHrs = curent_consumption - monthly_budget_in_hours;

        // number formats
        item.project.monthly_budget_in_hours = monthly_budget_in_hours;
        item.project.curent_consumption =
          Math.round(curent_consumption * 10) / 10;
        item.project.over_consumed_hrs =
          overConsumedHrs > 0 ? Math.round(overConsumedHrs * 10) / 10 : 0;

        item.project.credit_balance = credit_balance;
        let overBudget = 0;
        if (credit_balance < 0) {
          overBudget = Math.abs(credit_balance) * overBudgetHourlyRate;
        }
        item.project.overBudget = overBudget;
        item.project.total_fee = overBudget + minimalMonthlyBudget;
        return item;
      });
      return data;
    } catch (getReportError) {
      return {};
    }
  }
);

export const cloudOpsSlice = createSlice({
  name: 'cloudOps',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<ReportData>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(getCloudOpsReports.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

export const { setData } = cloudOpsSlice.actions;

export default cloudOpsSlice.reducer;
