export const breakpoints = {
  xs: '480px',
  sm: '1024px',
  md: '1024px',
  lg: '1200px',
  xl: '1536px',
};

export const palette = {
  primary: {
    main: '#6f9fe9',
    secondary: 'rgba(111, 159, 233, .2)',
  },
  secondary: {
    main: '#6f9fe9',
  },
  success: {
    main: '#004C56',
    150: '#A1EED0',
    500: '#10B981',
  },
  error: {
    main: '#FC3636',
    200: '#FECACA',
    300: '#F6BFBF',
    400: '#EF4444',
    500: '#EF4444',
    600: '#D97706',
    700: '#B45309',
  },
  warning: {
    main: '#F19D41', // TODO: check if this is correct
    200: '#FDE68A',
    400: '#FBBF24',
    500: '#F19D41', // TODO: check if this is correct
  },
  text: {
    primary: '#2B2B2B',
    secondary: '#485651',
  },
  breakpoints: {
    values: breakpoints,
  },
};
