import { TextField, Card, CardContent, Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { palette } from 'utils/styles/variables';
import { neutrals } from 'utils/styles/color';

export const AlertBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 3px #999999;
  border-radius: 4px;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 16px;
`;

export const CompanyInfo = styled.div<{
  $color?: string;
}>`
  display: flex;
  min-height: 32px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  svg {
    color: ${({ $color }) => $color || palette.primary.main};
    cursor: pointer;
    width: 20px;
    &:hover {
      opacity: 0.7;
    }
  }

  & > .MuiBox-root {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    min-height: 32px;
  }
`;

export const CompanyInfoDetails = styled.div`
  display: flex;
  margin-top: 9px;
  color: #31363e;
  font-weight: 600;
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  svg {
    margin-right: 16px;
  }
`;

export const CompanySettingsBox = styled(CardContent)`
  && {
    padding: 0;
    .MuiBox-root {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 35px;

      > span {
        font-family: Poppins, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #3e444d;
      }
    }
  }
`;

export const AddProject = styled.div`
  cursor: pointer;
  color: ${palette.primary.main};
  font-family: Poppins, sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-decoration-line: underline;
  text-transform: uppercase;
`;

export const Cancel = styled.div`
  font-size: 13px;
  color: #999999;
  cursor: pointer;
  white-space: nowrap;
  font-weight: 600;
  text-decoration-line: underline;
`;

export const ProjectModalStatistics = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 24px;
`;

export const ProjectModalStatisticsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 0;
  margin-bottom: 10px;
  width: 40%;
`;

export const ProjectModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const ConfirmModalContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 13px;
  flex-grow: 1;
  justify-content: space-between;
`;

export const CancelButtonWrapper = styled.div`
  .MuiButton-root {
    border: 1px solid #6f9fe9;
    width: 97px;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;
    color: #6f9fe9;

    &:hover {
      background-color: #6f9fe926;
      border: 1px solid #e8e9eb;
    }
  }
`;

export const ConfirmButtonWrapper = styled.div`
  .MuiButton-root {
    border: 1px solid #6f9fe9;
    background-color: #6f9fe9;
    width: 130px;
    height: 44px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 14px;

    &.lg {
      width: 158px;
    }

    &:hover {
      background-color: #8aafe9;
      border-color: #8aafe9;
    }

    &:disabled {
      background: #e8e9eb;
      border-color: #e8e9eb;
    }
  }
`;

export const CloudOpsCard = styled(Card)`
  && {
    height: 382px;
    position: relative;

    .MuiCardHeader-root {
      padding: 16px 12px 0;
      .MuiCardHeader-avatar {
        margin-right: 12px;
      }

      .custom-header {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .MuiTypography-root {
          color: ${neutrals[900]};

          font-family: Poppins, sans-serif;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;
        }
      }
    }
  }
`;

export const WhiteBorderTextField = styled(TextField)`
  height: 40px;
  margin: 0 !important;
  & label.Mui-focused {
    color: #1890ff;
  }
  & .MuiOutlinedInput-root {
    height: 40px;
    &.Mui-focused fieldset {
      border-color: #1890ff;
    }
  }
  & .MuiOutlinedInput-root fieldset legend span {
    font-size: 0.75rem;
  }
  & .MuiOutlinedInput-input {
    padding: 7px 8px !important;
  }
  & input {
    font-size: 0.875rem;
  }
  & label {
    font-size: 1rem;
  }
  .MuiFormHelperText-root {
    line-height: 18px;
    margin-top: 0;
  }
`;

export const CompanyInfoLeftColumnStyled = styled(Grid)`
  && {
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;

    border-right: 1px solid ${neutrals[200]};
    p {
      overflow-wrap: anywhere;
    }
  }
`;

export const StyledBody2 = styled(Typography)`
  && {
    display: block;
    -webkit-line-clamp: 3;
    flex: 1 0 0;

    overflow: hidden;
    color: ${neutrals[700]};
    text-overflow: ellipsis;

    font-family: Poppins, sans-serif;
    font-size: 0.813rem;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;

    word-wrap: break-word;
  }
`;

export const CompanyDetailsText = styled(Typography)`
  && {
    color: ${neutrals[700]};
    font-size: 0.75rem;
    font-weight: 400;
  }
`;
