import React, { useState, useCallback, useEffect } from 'react';

// components
import {
  Box,
  AutocompleteRenderInputParams,
  CircularProgress,
} from '@mui/material';

// utils
import axios from 'axios';
import { filterOptions } from 'organisms/ProjectDialogs/utils';

// types
import { CloudOpsProjectsType } from 'pages/CompanyPage/types';

// styles
import {
  CustomAutocomplete,
  StyledInputAdornment,
} from './ProjectAutoComplete.styles';
import { TextFieldCustomized } from 'atoms/TextFieldCustomized';

interface ProjectAutoCompleteProps {
  smallLabel?: boolean;
  disabled: boolean;
  submitted: boolean;
  project?: CloudOpsProjectsType;
  onChange: (p: string | CloudOpsProjectsType | null) => void;
  loading?: boolean;
}

export const ZStreamProjectAutoComplete = ({
  smallLabel,
  disabled,
  submitted,
  project,
  onChange,
  loading,
}: ProjectAutoCompleteProps) => {
  const [autoCompleteOpen, setAutoCompleteOpen] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string>('');

  const [cloudOpsProjectsList, setCloudOpsProjectsList] = useState<
    Array<CloudOpsProjectsType>
  >([]);
  const [cloudOpsProjectSelected, setCloudOpsProjectSelected] = useState<
    string | CloudOpsProjectsType | null
  >(null);

  const handleAutoCompleteOpen = useCallback(() => {
    setAutoCompleteOpen(true);
  }, []);

  const handleAutoCompleteClose = useCallback(() => {
    setAutoCompleteOpen(false);
  }, []);

  const handleAutoCompleteChange = useCallback(
    (event, newValue: string | CloudOpsProjectsType | null) => {
      setCloudOpsProjectSelected(newValue);
    },
    []
  );

  // TODO: Implement debouncing
  const handleAutoCompleteInputChange = useCallback(
    (event: React.SyntheticEvent<EventTarget>, newInputValue: string) => {
      axios
        .get(
          `${process.env.REACT_APP_USER_SERVICE}/projects/zstream?search=${newInputValue}`
          // { transformResponse: [data  => data] },
        )
        .then((response) => {
          setCloudOpsProjectsList(response.data.projects);
        });

      setInputValue(newInputValue);

      // If it's creation mode we need to show autocomplete options;
      if (!disabled) {
        setAutoCompleteOpen(newInputValue.length > 0);
      }
    },
    [disabled]
  );

  useEffect(
    () => {
      onChange(cloudOpsProjectSelected);
    },
    // eslint-disable-next-line
    [cloudOpsProjectSelected]
  );

  useEffect(() => {
    if (project?.id) setCloudOpsProjectSelected(project);
    handleAutoCompleteClose();
    // eslint-disable-next-line
  }, []);

  const renderAutoCompleteInput = (
    params: AutocompleteRenderInputParams
  ): JSX.Element => {
    const { InputProps } = params;

    const label =
      project?.company_hs_id && project?.quickbook_project_id
        ? 'Project name/key'
        : 'zStream project name/key';

    return (
      <TextFieldCustomized
        {...params}
        sx={{
          mt: '7px',
          'legend span': !smallLabel ? { fontSize: '0.75rem !important' } : {},
        }}
        error={submitted && !cloudOpsProjectSelected}
        label={
          <Box
            sx={{
              display: 'flex',
              ...(!smallLabel ? { fontSize: '1rem' } : {}),
              fontWeight: 400,
              color: '#999999',
            }}
          >
            <span>{label}</span>
          </Box>
        }
        InputProps={{
          ...InputProps,
          endAdornment: (
            <StyledInputAdornment position="end">
              {loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                typeof cloudOpsProjectSelected !== 'string' &&
                cloudOpsProjectSelected?.key
              )}
            </StyledInputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  };

  const renderAutoCompleteOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: CloudOpsProjectsType
  ) => (
    <li {...props}>
      <Box
        display="flex"
        flex="1"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          fontFamily: 'Poppins, sans-serif',
          color: '#3E444D',
          fontSize: '1rem',
        }}
      >
        <Box>
          <span>{option.name}</span>
        </Box>
        <Box>
          <span>{option.key}</span>
        </Box>
      </Box>
    </li>
  );

  return (
    <CustomAutocomplete
      freeSolo
      size="small"
      loading={loading}
      disabled={disabled}
      options={cloudOpsProjectsList}
      value={cloudOpsProjectSelected}
      getOptionLabel={(option: CloudOpsProjectsType) =>
        `${option.name.toString()}`
      }
      open={autoCompleteOpen}
      onOpen={handleAutoCompleteOpen}
      onClose={handleAutoCompleteClose}
      inputValue={inputValue}
      onInputChange={handleAutoCompleteInputChange}
      onChange={handleAutoCompleteChange}
      filterOptions={filterOptions}
      renderInput={renderAutoCompleteInput}
      renderOption={renderAutoCompleteOption}
    />
  );
};
